<template src="./AcademicPlantResearchersRequests.html"></template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import idiService from "../../services/Idi";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import { mapGetters } from "vuex";

export default {
  name: "academicPlantResearchersRequests",
  components: {
    ArrobaMedellinSeeker,
    ArrobaMedellinArrobitaNoData,
    ChooseUniversity,
  },
  props: {
    TitleNotFound: {
      type: String,
      default: "Lista de solicitudes",
    },
    notFound: {
      type: String,
      default: "No hay solicitudes en éste momento",
    },
  },
  data() {
    return {
      errorMessage: false,
      countAcademicPlant: {},
      researchersRequests: [],
      institutionId: "",
      modalGivePermissions: false,
      userInfo: { nombres: "", id: "" },
      role: "",
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.infoAcademicPlant();
  },
  methods: {
    async infoAcademicPlant() {
      try {
        this.researchersRequests =
          await idiService.getRequestsResearchersByInstitution(
            this.institutionId
          );
        this.countAcademicPlant = await idiService.countAcademicPlant(
          this.institutionId
        );
      } catch (error) {
        console.error(error);
      }
    },
    redirectProfileUser(path, follow) {
      let routeData = this.$router.resolve({
        name: path,
        params: { id: follow },
      });
      window.open(routeData.href, "_blank");
    },
    showModalGivePermissions(user) {
      this.userInfo = user;
      this.modalGivePermissions = true;
    },
    async givePermissions() {
      try {
        const obj = {
          state: "accepted",
        };
        await idiService.updateStateResearchProfessorApplication(
          obj,
          this.userInfo.id
        );
        this.$buefy.toast.open({
          duration: 5000,
          message: "Se actualizaron los permisos correctamente",
          position: "is-bottom",
          type: "is-success",
        });
        this.infoAcademicPlant();
        this.modalGivePermissions = false;
      } catch (error) {
        console.error(error);
      }
    },
    async rejectedPermissions(user) {
      try {
        this.userInfo = user;
        const obj = {
          state: "rejected",
        };
        await idiService.updateStateResearchProfessorApplication(
          obj,
          this.userInfo.id
        );
        this.$buefy.toast.open({
          duration: 5000,
          message: "Se actualizaron los permisos correctamente",
          position: "is-bottom",
          type: "is-success",
        });
        this.infoAcademicPlant();
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidationAdministrator() {
      return ["Administrator"].some((a) => a === this.role);
    },
    ...mapGetters({
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.infoAcademicPlant();
    },
  },
};
</script>

<style lang="scss" scoped src="./AcademicPlantResearchersRequests.scss"></style>